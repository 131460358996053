import axios from '@axios'

const PREFIX = 'situacoes-fiscais'

export default {

  getPaginado(page, pageSize, filtros) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}`, filtros)
  },

  getURL(id) {
    return axios.get(`${PREFIX}/${id}/url`)
  },

  getPendenciasSituacaoFiscal(id) {
    return axios.get(`${PREFIX}/${id}/pendencias`)
  },

  baixaEmLote(filtros, nomeArquivo) {
    return axios.post(`${PREFIX}/baixa-lotes?nomeArquivo=${nomeArquivo}`, filtros)
  },

  notificaSF(nomeEmpresa, emailEmpresa, emailCc, id, mensagemAdicional, definirEmailPadrao) {
    return axios.post(`${PREFIX}/${id}/notifica?emailEmpresa=${emailEmpresa}&emailCc=${emailCc}&nomeEmpresa=${nomeEmpresa}&mensagemAdicional=${encodeURIComponent(mensagemAdicional)}&definirEmailPadrao=${definirEmailPadrao}`)
  },

  getTotalFiltroSituacaoFiscal(filtros) {
    return axios.post(`${PREFIX}/total-filtro`, filtros)
  },

  baixarEmExcel(tipo, filtros) {
    return axios.post(`${PREFIX}/${tipo}/baixa-excel`, filtros)
  },

  deletarExcel(url, nomeArquivo) {
    axios.post(`${PREFIX}/deletar-excel?url=${url}&nomeArquivo=${nomeArquivo}`)
  },

  notificaHistorico(nomeEmpresa, emailEmpresa, emailCc, mensagemAdicional, definirEmailPadrao, ids) {
    return axios.post(`${PREFIX}/notifica-historico?emailEmpresa=${emailEmpresa}&emailCc=${emailCc}&nomeEmpresa=${nomeEmpresa}&mensagemAdicional=${encodeURIComponent(mensagemAdicional)}&definirEmailPadrao=${definirEmailPadrao}`, ids)
  },

  getHistorico(tipo, id, filtros) {
    return axios.post(`${PREFIX}/historico?empresaId=${id}`, filtros)
  },

  baixarSelecionados(ids) {
    return axios.post(`${PREFIX}/baixar-selecionadas`, ids)
  },

  GetUrlWhatsApp(ids) {
    return axios.post(`${PREFIX}/url-whatsapp`, ids)
  },

  updateSituacaoFiscal(empresaId) {
    return axios.post(`${PREFIX}/atualizacao?empresaId=${empresaId}`)
  },

  getConsumoCredito(empresasFiltro) {
    return axios.post(`${PREFIX}/credito/consumo`, empresasFiltro)
  },

  atualizarManual(empresasFiltro) {
    return axios.post(`${PREFIX}/atualizar-em-lote`, empresasFiltro)
  },

  getEmpresasAtualizacaoEmLote() {
    return axios.get(`${PREFIX}/empresas`)
  },

  getEmpresasAtualizacaoEmLoteFiltrado(body) {
    return axios.post(`${PREFIX}/empresas`, body)
  },
}
